import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { logout } from '../sdks/firebase';
import { useNavigate } from 'react-router-dom';

export const UserMenuButton = () => {

  const [isVisible, setIsVisible] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleToggle = () => {
    setIsVisible((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (parentRef.current && !parentRef.current.contains(event.target as Node)) {
      setIsVisible(false); // 외부 클릭 시 숨김
    }
  };

  const handleClickMyAccount = () => {
    navigate('/wip');
  };

  const handleClickMyDeals = () => {
    navigate('/wip');
  };

  const handleClickMyItems = () => {
    navigate('/wip');
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <ItemLayout ref={parentRef} >
      <ItemButton url='imgs/menu-icon.svg' onClick={handleToggle} />
      <UserMenuModal isVisible={isVisible}>
        <UserMenuElement onClick={handleClickMyAccount}>내 계정</UserMenuElement>
        <UserMenuDivider />
        <UserMenuElement onClick={handleClickMyDeals}>내 거래 내역</UserMenuElement>
        <UserMenuElement onClick={handleClickMyItems}>내 물품 관리</UserMenuElement>
        <UserMenuDivider />
        <UserMenuElement onClick={logout}>로그 아웃</UserMenuElement>
      </UserMenuModal>
    </ItemLayout>
  );
};


const ItemLayout = styled.div`
  display: flex;
`;

const ItemButton = styled.button<{ url: string }>`
  background-image: url(${(props) => props.url});
  background-color: transparent;
  width: 50px;
  height: 50px;
  color: rgba(255, 255, 255, 1);
  border: none;
  cursor: pointer;
  font: 24px Inconsolata, sans-serif;

  &:hover {
    opacity: 0.8;
  }
`;

const UserMenuDivider = styled.div`
  height : 2px;
  background-color: #FFF;
`;

const UserMenuElement = styled.div`
  width : 250px;
  background-color: transparent;
  font: 700 18px 'Courier New', cursive;
  text-align: right;
  cursor: pointer;
`;

const UserMenuModal = styled.div<{ isVisible: boolean }>`
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};

  position: absolute;
  display: flex;
  flex-direction: column;

  z-index : 999;
  top: 80px;
  right: 30px;
  gap: 5px;

  background-color: #FFAA71AA; /* 80% 투명한 배경색 */
  backdrop-filter: blur(2px);

  padding: 10px;
  border: 2px solid #FFF;
  border-radius: 4px;
  box-shadow: 4px 4px 15px 3px rgba(0, 0, 0, 0.25);
`;
import React from 'react';
import styled from 'styled-components';
import { ItemCardProps } from '../types/types';

export const ItemCard: React.FC<ItemCardProps> = ({
    title,
    postedDate,
    updatedDate,
    postedBy,
    location,
    description,
    price,
    originalPrice,
    imageUrl,
    isNew,
    status,
    isFree
}) => {
    return (
        <CardContainer>
            <ImageContainer>
                <ImageWrapper>
                    <ItemImage src={imageUrl} alt={title} loading="lazy" />
                    {isNew && (
                        <TagContainer>
                            <Tag>NEW</Tag>
                        </TagContainer>
                    )}
                </ImageWrapper>
            </ImageContainer>
            <ContentContainer>
                <TitleSection>
                    <Title>{title}</Title>
                </TitleSection>
                <InfoSection>
                    <PostInfo>Posted @ <strong>{postedDate}</strong></PostInfo>
                    <PostInfo>Updated @ <strong>{updatedDate}</strong></PostInfo>
                    <Spacer />
                    <PostInfo>Posted by <strong>{postedBy}</strong></PostInfo>
                    <Spacer />
                    <LocationInfo>
                        <span>Location: </span>{location}
                    </LocationInfo>
                    <Description>
                        <span>Description: </span>
                        {description}
                    </Description>
                </InfoSection>
                <PriceSection>
                    {originalPrice && (
                        <OriginalPrice>${originalPrice}</OriginalPrice>
                    )}
                    <CurrentPrice>
                        {isFree ? 'FREE' : `$${price}`}
                    </CurrentPrice>
                </PriceSection>
            </ContentContainer>
            {status && (
                <StatusOverlay>
                    {status}
                </StatusOverlay>
            )}
        </CardContainer>
    );
};

const CardContainer = styled.div`
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 4px 4px 15px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 240px;
  height: 190px;
  position: relative;
  padding: 10px;
  gap: 10px;
  width: 344px;
  
  @media (max-width: 991px) {
    width: calc(100% - 20px);
  }
`;

const ImageContainer = styled.div`
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
  width: 130px;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-bottom: 146px;
  width: 100%;
`;

const ItemImage = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TagContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
`;

const Tag = styled.div`
  background-color: rgba(255, 122, 50, 1);
  color: #fff;
  border-radius: 8px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 700;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 240px;
`;

const TitleSection = styled.div`
  color: rgba(223, 177, 51, 1);
  font-family: 'Inria Sans', sans-serif;
  font-size: 15px;
  height : 20px;
  overflow: hidden;
  margin-bottom: 10px;
`;

const Title = styled.h3`
  margin: 0;
  font-weight: 700;
`;

const InfoSection = styled.div`
  flex: 1;
  font-size: 10px;
  color: rgba(174, 174, 174, 1);
`;

const PostInfo = styled.div`
  margin-bottom: 4px;
`;

const Spacer = styled.div`
  height: 4px;
`;

const LocationInfo = styled.div`
  font-weight: 700;
  margin-bottom: 4px;
`;

const Description = styled.div`
  margin-top: 4px;
`;

const PriceSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const OriginalPrice = styled.div`
  color: #C8C8C8;
  font-size: 10px;
  text-decoration: line-through;
`;

const CurrentPrice = styled.div`
  color: rgba(223, 177, 51, 1);
  font-size: 20px;
  font-weight: 700;
`;

const StatusOverlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(223, 177, 51, 0.9);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: 800;
  letter-spacing: 28.8px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 1);
`;
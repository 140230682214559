import React from 'react';
import styled from 'styled-components';

import { FilterButtonProps } from '../types/types';

export const FilterButton: React.FC<FilterButtonProps> = ({
  label,
  isActive,
  onClick
}) => {
  return (
    <Button isActive={isActive} onClick={onClick}>
      {label}
    </Button>
  );
};

const Button = styled.button<{ isActive?: boolean }>`
  border-radius: 5px;
  background-color: ${({ isActive }) => (isActive ? '#fff' : 'transparent')};
  color: ${({ isActive }) => (isActive ? 'rgba(232, 218, 162, 1)' : '#fff')};
  padding: 5px 4px;
  font-family: 'Inria Sans', sans-serif;
  font-weight: 700;
  font-size: 12px;
  border: none;
  cursor: pointer;
  height: 24px;
  min-height: 24px;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    outline: 2px solid #fff;
    outline-offset: 2px;
  }
`;
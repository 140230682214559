export const isZipcodeInState = async (zipcode: string, state: string): Promise<boolean> => {
  const response = await fetch(`https://api.zippopotam.us/us/${zipcode}`);

  if (!response.ok) {
    console.log(`Zipcode "${zipcode}" not found.`);
    return false;
  }

  const data = await response.json();
  const stateName = data.places[0]?.["state"] as string;
  const stateAbbreviation = data.places[0]?.["state abbreviation"] as string;

  if (state.toLowerCase() === stateName.toLowerCase() || state.toLowerCase() === stateAbbreviation.toLowerCase()) {
    console.log(`Zipcode "${zipcode}" is in ${state}.`);
    return true;
  } else {
    console.log(`Zipcode "${zipcode}" is not in ${state}.`);
    return false;
  }
}

export const fetchZipCodes = async (city: string): Promise<[] | null> => {

  const response = await fetch(`https://api.zippopotam.us/us/${city}`);
  if (!response.ok) {
    return null;
  }

  const data = await response.json();

  const zipCodes = data.places.map((place: { "post code": string }) => place["post code"]);
  return zipCodes;
};

export const fetchCityState = async (city: string): Promise<string | null> => {
  try {
    // Zippopotam API 호출
    const response = await fetch(`https://api.zippopotam.us/us/${city}`);
    if (!response.ok) {
      return null;
    }

    const data = await response.json();

    // 데이터에서 State 정보 추출
    const state = data.places[0]?.state;
    return state;
  } catch (error) {
    if (error instanceof Error) {
      console.error("Error fetching city data:", error.message);
    } else {
      console.error("Error fetching city data:", error);
    }
    return null;
  }
};

export const isCityInState = async (city: string, state: string): Promise<boolean> => {
  const stateFromCity = await fetchCityState(city);
  if (!stateFromCity) {
    console.log(`City "${city}" not found.`);
    return false;
  }

  const isInState = state.toLowerCase() === state;
  console.log(
    `City "${city}" is in ${state}: ${isInState ? "Yes" : "No"}`
  );
  return isInState;
};

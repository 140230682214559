import styled from 'styled-components';
import { ButtonProps } from '../types/types';

export const Button = ({ children, enable, type, onClick, variant = 'wide' }: ButtonProps) => {
  return (
    (enable ?
      (<EnabledButton type={type} variant={variant} onClick={onClick}>
        {children}
      </EnabledButton>) :
      (<DisabledButton disabled variant={variant}>
        {children}
      </DisabledButton>))
  );
};

const ButtonLayout = styled.button<{ variant: 'round' | 'wide' }>`
  border-radius: ${props => props.variant === 'round' ? '50px' : '20px'};
  min-height: 50px;
  width: ${props => props.variant === 'round' ? '50px' : '150px'};
  color: rgba(255, 255, 255, 1);
  border: none;
  cursor: pointer;
  font: 18x Inconsolata, sans-serif;
  padding: ${props => props.variant === 'round' ? '0 10px' : '13px 10px'};
`;

const EnabledButton = styled(ButtonLayout)`
  background-color: rgba(51, 177, 223, 1);
`;

const DisabledButton = styled(ButtonLayout)`
  background-color: #CCC;
`;
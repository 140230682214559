
import React from 'react';
import styled from 'styled-components';

type ProfileProps = {
  photoURL: string | null;
  userInitial: string | undefined;
};

export const UserProfile: React.FC<ProfileProps> = ({ photoURL, userInitial }) => {
  return (
    photoURL ?
      (<ProfileImage src={photoURL!} alt="Profile" />) :
      (<DefaultImage> {userInitial} </DefaultImage>)
  );
};

const ProfileImage = styled.img`
  margin: 0px 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const DefaultImage = styled.div`
  display: flex;
  margin: 0px 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #FC0;
  text-align: center;

  justify-content: center;
  align-items: center;
`;
// Import the functions you need from the SDKs you need
import { FirebaseError, initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  signInWithEmailAndPassword
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDhmlTevbFnteupVjAWrKTs5h1jEhRG5ws",
  authDomain: "calimaru-website.firebaseapp.com",
  projectId: "calimaru-website",
  storageBucket: "calimaru-website.firebasestorage.app",
  messagingSenderId: "459699388770",
  appId: "1:459699388770:web:21004d94cdcb716aaa6c3b",
  measurementId: "G-FMVDK7R6LM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const analytics = getAnalytics(app);

export const loginWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    // 로그인 성공 시 사용자 정보 가져오기
    const user = result.user;
    console.log('Logged In:', user);
  } catch (error) {
    console.error('Failed To Login:', error);
  }
};

export const loginWithEmailAndPassword = async (email: string, password: string): Promise<string> => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return "success";
  } catch (error) {
    const firebaseError = error as FirebaseError;
    console.error('Failed To Login:', firebaseError.code);
    switch (firebaseError.code) {
      case "auth/invalid-credential":
        return "등록되지 않은 이메일 이거나 비밀번호가 틀렸습니다.";
      case "auth/user-not-found":
        return "등록되지 않은 이메일입니다.";
      case "auth/wrong-password":
        return "비밀번호가 올바르지 않습니다.";
      case "auth/invalid-email":
        return "	유효하지 않은 이메일 주소입니다.";
      case "auth/user-disabled":
        return "사용자가 비활성화된 계정입니다.";
      case "auth/too-many-requests":
        return "로그인 시도가 너무 많아 인증이 일시적으로 차단되었습니다.";
      case "auth/network-request-failed":
        return "네트워크 연결 문제가 발생했습니다.";
      case "auth/internal-error":
        return "Firebase 내부에서 알 수 없는 오류가 발생했습니다.";
      case "auth/email-already-in-use":
        return "이메일이 이미 다른 계정에 사용 중입니다.";
      case "auth/invalid-password":
        return "비밀번호 형식이 유효하지 않습니다.";
      default:
        return "알 수 없는 오류가 발생했습니다.";
    }
  }
}

export const logout = async () => {
  try {
    await auth.signOut();
    console.log('Logged Out');
  } catch (error) {
    console.error('Failed To Logout:', error);
  }
}

export const register = async (username: string, email: string, password: string): Promise<string> => {
  try {
    const UserCredential = await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(UserCredential.user, { displayName: username });
    await sendEmailVerification(UserCredential.user);
    return "success";

  } catch (error) {
    const firebaseError = error as FirebaseError;

    switch (firebaseError.code) {
      case "auth/email-already-in-use":
        return "이미 사용 중인 이메일입니다.";
      case "auth/invalid-email":
        return "유효하지 않은 이메일입니다.";
      case "auth/weak-password":
        return "비밀번호는 6자 이상이어야 합니다.";
      case "auth/operation-not-allowed":
        return "이메일/비밀번호 계정이 활성화되지 않았습니다.";
      case "auth/network-request-failed":
        return "네트워크 연결을 확인해주세요.";
      case "auth/internal-error":
        return "내부에 알수 없는 오류가 발생했습니다.";
      case "auth/too-many-requests":
        return "너무 많은 요청이 있습니다. 나중에 다시 시도해주세요.";
      case "auth/invalid-password":
        return "유효하지 않은 비밀번호입니다.";
      case "auth/invalid-credential":
        return "제공된 인증 정보가 잘못되었습니다.";
      default:
        return "알 수 없는 오류가 발생했습니다.";
    }
  }
}

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const PASSWORD_VALIDATION_FLAGS = {
  MIN_LENGTH: 1 << 0, // 0001 (1)
  HAS_UPPERCASE: 1 << 1, // 0010 (2)
  HAS_LOWERCASE: 1 << 2, // 0100 (4)
  HAS_NUMBER: 1 << 3, // 1000 (8)
  HAS_SPECIAL_CHAR: 1 << 4, // 10000 (16)
};

type ValidationFlags = number;

export const validatePassword = (password: string): ValidationFlags => {
  let flags = 0;

  if (password.length >= 6) {
    flags |= PASSWORD_VALIDATION_FLAGS.MIN_LENGTH;
  }
  if (/[A-Z]/.test(password)) {
    flags |= PASSWORD_VALIDATION_FLAGS.HAS_UPPERCASE;
  }
  if (/[a-z]/.test(password)) {
    flags |= PASSWORD_VALIDATION_FLAGS.HAS_LOWERCASE;
  }
  if (/[0-9]/.test(password)) {
    flags |= PASSWORD_VALIDATION_FLAGS.HAS_NUMBER;
  }
  if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    flags |= PASSWORD_VALIDATION_FLAGS.HAS_SPECIAL_CHAR;
  }
  return flags;
}

export { auth, provider, analytics };
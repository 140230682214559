import {ReactNode} from "react";
import styled from "styled-components";

import { useNavigate, useMatch } from 'react-router-dom';
import { PrivateSection } from './PrivateSection';

export const DefaultLayout = ({ children } : { children? : ReactNode}) => {
  return (
    <PageContainer>
      <HeaderLayout />
      <BodyLayout>
        {children}
      </BodyLayout>
    </PageContainer>
  )
}

export const HeaderLayout = () => {
  const isRoot = useMatch('/');
  const navigate = useNavigate();

  const handleLogoClick = () => {
    if (isRoot) {
      window.location.reload();
    }
    else {
      navigate('/');
    }
  };

  return (
    <Header>
      <Logo icon='/imgs/calimaru-icon.png' onClick={handleLogoClick} />
      <Subtitle>캘리포니아에 사는 한인들을 위한 거래 마루터</Subtitle>
      <SpaceBetweenLogoAndWelcome />
      {(isRoot ? <PrivateSection /> : <></>)}
    </Header>
  );
};

export const PageContainer = styled.div`
  background-color: #fff;
  width: 100vw;
  height: 100vh;
`;

export const BodyLayout = styled.main`
  background-color: #ffaa71;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  width: 100vw;
  height: calc(100vh - 80px);

  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #999 #FFF0;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #FFF0;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

`;

export const Title = styled.div`
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 1.72px;
  font: 700 26px 'Courier New', cursive;
  margin: 0;
  
  @media (max-width: 991px) {
    padding: auto;
    font-size: 18px;
  }
`

export const Subtitle = styled.div`
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 2.72px;
  font: 700 12px 'Courier New', cursive;
  margin: 0;
  
  @media (max-width: 991px) {
    padding: auto;
    font-size: 12px;
  }
`;

export const ImageLayout = styled.div<{url : string}>`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;

  width: auto;
  height: auto;
`

export const Text = styled.p`
  color: #fff;
  text-align: center;
  font: 400 14px Inconsolata, sans-serif;
`;

export const Link = styled.span`
  font-weight: 700;
  text-decoration: underline;
  color: rgba(80, 124, 255, 1);
  cursor: pointer;
`;

const Header = styled.header`
  display: flex;
  background-color: #FFAA71;
  height: 80px;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  gap: 20px;
  flex-wrap: wrap;
`;

const Logo = styled.button<{icon : string}>`
  background-image: url(${(props) => props.icon});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;

  border: none;
  width: 190px;
  height: 60px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const SpaceBetweenLogoAndWelcome = styled.div`
  flex-grow: 1;
  height: 0px;
`;
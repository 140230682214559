import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { MainPage } from './pages/MainPage';
import { LoginPage } from './pages/LoginPage';
import { SignupPage } from "./pages/SignupPage";
import { ProtectedRoute } from "./components/Route";
import { NotFoundPage } from "./pages/NotFoundPage";
import { WIPPage } from "./pages/WIPPage";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <MainPage />
        } />

        <Route path="/login" element={
          <ProtectedRoute>
            <LoginPage />
          </ProtectedRoute>
        } />

        <Route path="/signup" element={
          <ProtectedRoute>
            <SignupPage />
          </ProtectedRoute>
        } />

        <Route path="/wip" element={
          <WIPPage />
        } />

        <Route path="*" element={
          <NotFoundPage />
        } />
      </Routes>
    </Router>
  );
}

export default App;

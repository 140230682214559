import React from 'react';
import styled from 'styled-components';

import { DefaultLayout } from '../components/DefaultLayout';
import { SearchBar } from '../components/SearchBar';
import { FilterButton } from '../components/FilterButton';
import { ItemCard } from '../components/ItemCard';
import { ItemCardProps } from '../types/types';

const marketplaceItems: ItemCardProps[] = [
  {
    title: "[Sample]",
    postedDate: "11 - 22 - 2024 / 09:00AM",
    updatedDate: "11 - 22 - 2024 / 01:00PM",
    postedBy: "J",
    location: "Torrey Pines, 92130",
    description: "1장 있습니다~ 서둘러 주세요 : )",
    price: "20.00",
    originalPrice: "30.00",
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/65eeb02b108b107968890c8f548486a0af30e71bf3597ffc68a2c349307a0a27?placeholderIfAbsent=true&apiKey=c64922cf6ac24a118e37476a97096949",
    isNew: true
  }
  // Add more items here...
];

export const MainPage: React.FC = () => {
  const handleSearch = (value: string) => {
    // Implement search functionality
  };

  return (
    <DefaultLayout>
      <Banner>
        <BannerTitle>No Fee For Flea</BannerTitle>
        <BannerSubtitle>
          Buy, Sell, and Trade Without Extra Costs
        </BannerSubtitle>
        <BannerSpace />
        <SearchBar
          placeholder="찾고 계신게 있으신가요?"
          onSearch={handleSearch}
        />
      </Banner>

      <FilterSection>
        <FilterGroup>
          <FilterButton label="날짜" onClick={() => { }} />
          <FilterButton label="가격" isActive onClick={() => { }} />
          <FilterButton label="거리" onClick={() => { }} />
        </FilterGroup>
        <FilterGroup>
          <FilterButton label="거래중" onClick={() => { }} />
          <FilterButton label="거래됨" onClick={() => { }} />
        </FilterGroup>
      </FilterSection>

      <ItemSection>
        <ItemsGrid>
          {marketplaceItems.map((item, index) => (
            <ItemCard key={index} {...item} />
          ))}
        </ItemsGrid>
      </ItemSection>
    </DefaultLayout>
  );
};

const Banner = styled.div`
  background-color: #FFFFFF;
  display: flex;
  width: 100%;
  height: 60px;

  gap: 20px;
  
  justify-content: right;
  align-items: center;

`;

const BannerTitle = styled.h4`
  margin: 0px 20px;
  color: #FFAA7188;
  font: 600 38px;
`;

const BannerSubtitle = styled.p`
  color: #FFAA7188;
  font: 400 18px;
`;

const BannerSpace = styled.div`
  flex-grow: 1;
  height: 0px;
`;

const FilterSection = styled.section`
  display: flex;
  width: 100%;
  height: 5vh;
  flex-wrap: wrap;
  align-items: center;
  
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const FilterGroup = styled.div`
  margin: 0px 20px;
  display: flex;
  gap: 10px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 5px;
`;

const ItemSection = styled.section`
  height: calc(95vh - 140px);

  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #999 #FFF0;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #FFF0;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (max-width: 991px) {
    height: calc(75vh - 80px);
  }
`;

const ItemsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 344px);
  align-content: center;
  justify-content: center;
  gap: 20px 40px;
  padding: 0px 20px;
`;
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// sdk
import { register, validateEmail, validatePassword } from "../sdks/firebase";
import { isZipcodeInState } from "../sdks/zipcode"

// design
import { DefaultLayout, ImageLayout, Link, Text } from "../components/DefaultLayout";
import { Dropdown, InputField } from "../components/InputLayout";
import { Button } from "../components/Button";

interface FormEvent extends React.FormEvent<HTMLFormElement> { }
interface ChangeEvent extends React.ChangeEvent<HTMLInputElement> { }

export const SignupPage = () => {
  const navigate = useNavigate();
  
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [zipcode, setZipcode] = React.useState("");

  const [openUsernameMessageBox, setOpenUsernameMessageBox] = React.useState(false);
  const [openEmailMessageBox, setOpenEmailMessageBox] = React.useState(false);
  const [openPasswordMessageBox, setOpenPasswordMessageBox] = React.useState(false);
  const [openConfirmPasswordMessageBox, setOpenConfirmPasswordMessageBox] = React.useState(false);
  const [openZipcodeMessageBox, setOpenZipcodeMessageBox] = React.useState(false);

  // 애러 관련 메시지
  const [usernameError, setUsernameError] = React.useState(true);
  const [emailError, setEmailError] = React.useState(true);
  const [passwordError, setPasswordError] = React.useState(0);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(true);
  const [zipcodeError, setZipcodeError] = React.useState(true);

  // result
  const [isRegisterReady, setIsRegisterReady] = React.useState(false);
  const [resultMessage, setResultMessage] = React.useState("");

  React.useEffect(() => {
    setIsRegisterReady(!usernameError && !emailError && passwordError === 31 && !confirmPasswordError);
  }, [usernameError, emailError, passwordError, confirmPasswordError]);

  const updateAndValidateUsername = (value: string) => {
    setUsername(value);
    setUsernameError(value.length < 2);
  }

  const updateAndVaildateEmail = (value: string) => {
    setEmail(value);
    setEmailError(!validateEmail(value));
  };

  const updateAndvalidatePassword = (value: string) => {
    setPassword(value);
    setPasswordError(validatePassword(value));
  };

  const updateAndValidateConfirmPassword = (value: string) => {
    setConfirmPassword(value);
    setConfirmPasswordError(value !== password);
  };

  const updateAndValidateZipcode = async (value: string) => {  
    setZipcode(value);
    const hasError = !(await isZipcodeInState(value, "CA")); 
    setZipcodeError(hasError);
  };

  const handleLoginButtonClick = () => {
    navigate("/login");
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const message = await register(username, email, password);
    // Zipcode는 별도의 database에 저장되어야합니다.
    setResultMessage(message);

    if (message === "success") {
      alert("일반 회원이 되셨습니다!. 더 많은 혜택을 위해 이메일 인증을 진행해주세요.");
    }

  };

  return (
    <DefaultLayout>
      <RegisterFormLayout onSubmit={handleSubmit}>
        <LoginImageLayout url="imgs/calimaru-title.png"/>
        <SpaceBetweenImageAndForm />
        <ViewSessionLayout>

          <LeftViewSessionLayout>
            <WelcomeImageLayout url="imgs/welcome.webp" />
          </LeftViewSessionLayout>

          <RightViewSessionLayout>
            <InputFieldWrapper haserror={username !== "" && usernameError}>
              <InputField
                type="username"
                name="username"
                value={username}
                placeholder="User Name"
                onFocus={() => setOpenUsernameMessageBox(true)}
                onBlur={() => setOpenUsernameMessageBox(false)}
                onChange={(e: ChangeEvent) => updateAndValidateUsername(e.target.value)} />
            </InputFieldWrapper>
            <ErrorMessageLayout isopen={openUsernameMessageBox}>
              {username && usernameError ? 
                (<ErrorMessage> ! 이름이 유효하지 않습니다. </ErrorMessage>) :
                (<InfoMessage> * 다른 사람들에게 노출되는 이름입니다.</InfoMessage>)}
            </ErrorMessageLayout>

            <InputFieldWrapper haserror={email !== "" && emailError}>
              <InputField
                type="email"
                name="text"
                value={email}
                placeholder="Email"
                onFocus={() => setOpenEmailMessageBox(true)}
                onBlur={() => setOpenEmailMessageBox(false)}
                onChange={(e: ChangeEvent) => updateAndVaildateEmail(e.target.value)} />
            </InputFieldWrapper>
            <ErrorMessageLayout isopen={openEmailMessageBox}>
              {email && emailError ?
                (<ErrorMessage> ! 이메일 형식이 올바르지 않습니다. </ErrorMessage>) :
                (<InfoMessage> * 해당 이메일로 검증 이메일이 발송됩니다.</InfoMessage>)}
            </ErrorMessageLayout>
            
            <InputFieldWrapper haserror={password !== "" && passwordError !== 31}>
              <InputField
                type="password"
                name="password"
                value={password}
                placeholder="Password"
                onFocus={() => setOpenPasswordMessageBox(true)}
                onBlur={() => setOpenPasswordMessageBox(false)}
                onChange={(e: ChangeEvent) => updateAndvalidatePassword(e.target.value)} />
            </InputFieldWrapper>
            <PasswordCheckBoxlayout isOpen={openPasswordMessageBox}>
              <PasswordCheckBox isready={((passwordError) & (1 << 0)) !== 0}> {((passwordError) & (1 << 0)) !== 0 ? <CheckIcon /> : <EmptyIcon />} 최소 6자 이상 </PasswordCheckBox>
              <PasswordCheckBox isready={((passwordError) & (1 << 1)) !== 0}> {((passwordError) & (1 << 1)) !== 0 ? <CheckIcon /> : <EmptyIcon />} 대문자 포함 </PasswordCheckBox>
              <PasswordCheckBox isready={((passwordError) & (1 << 2)) !== 0}> {((passwordError) & (1 << 2)) !== 0 ? <CheckIcon /> : <EmptyIcon />} 소문자 포함 </PasswordCheckBox>
              <PasswordCheckBox isready={((passwordError) & (1 << 3)) !== 0}> {((passwordError) & (1 << 3)) !== 0 ? <CheckIcon /> : <EmptyIcon />} 숫자 포함 </PasswordCheckBox>
              <PasswordCheckBox isready={((passwordError) & (1 << 4)) !== 0}> {((passwordError) & (1 << 4)) !== 0 ? <CheckIcon /> : <EmptyIcon />} 특수 문자 포함 </PasswordCheckBox>
            </PasswordCheckBoxlayout>

            <InputFieldWrapper haserror={confirmPassword !== "" && confirmPasswordError}>
              <InputField
                type="password"
                name="confirm-password"
                value={confirmPassword}
                placeholder="Confirm Password"
                onFocus={() => setOpenConfirmPasswordMessageBox(true)}
                onBlur={() => setOpenConfirmPasswordMessageBox(false)}
                onChange={(e: ChangeEvent) => updateAndValidateConfirmPassword(e.target.value)} />
            </InputFieldWrapper>
            <ErrorMessageLayout isopen={openConfirmPasswordMessageBox}>
              {confirmPassword && confirmPasswordError ?
                (<ErrorMessage> ! 비밀번호가 일치하지 않습니다. </ErrorMessage>) :
                (<InfoMessage> * 비밀번호를 한번 더 입력해주세요. </InfoMessage>)}
            </ErrorMessageLayout>
            
            <InputFieldWrapper 
              haserror={zipcode !== "" && zipcodeError}
              onFocus={() => setOpenZipcodeMessageBox(true)}
              onBlur={() => setOpenZipcodeMessageBox(false)}>
              <InputField
                type="zipcode"
                name="zipcode"
                value={zipcode}
                placeholder="Zip Code"
                onChange={(e: ChangeEvent) => updateAndValidateZipcode(e.target.value)} />
            </InputFieldWrapper>
            <ErrorMessageLayout isopen={openZipcodeMessageBox}>
              { zipcode && zipcodeError ?
                (<ErrorMessage> ! Zip Code를 확인해 주세요. California의 Zip Code가 맞나요? </ErrorMessage>) :
                (<InfoMessage> * 현재는 California의 유효한 Zip Code만 사용 가능합니다. </InfoMessage>)}
            </ErrorMessageLayout>

          </RightViewSessionLayout>
        </ViewSessionLayout>
        
        <Text> 이미 아이디가 있으시다구요? <Link onClick={handleLoginButtonClick}>로그인</Link>을 통해 더 많은 혜택을 누려보세요! </Text>
        <Button enable={isRegisterReady} type="submit"> Create Account </Button>

        <ErrorMessageLayout isopen={true}>
          <ErrorMessage> {resultMessage} </ErrorMessage>
        </ErrorMessageLayout>

      </RegisterFormLayout>
    </DefaultLayout>
  );
};

const LoginImageLayout = styled(ImageLayout)`
  width: 350px;
  height: 80px;
`

const RegisterFormLayout = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const ViewSessionLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
  align-items: center;
  overflow: hidden;
  
  border-radius: 10px;
  box-shadow: 4px 4px 15px 3px rgba(0, 0, 0, 0.25);
  
  @media (max-width: 991px) {
    flex-direction: column;
  }
`
const LeftViewSessionLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 450px;
`
const RightViewSessionLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 450px;
  gap: 5px;
`

const WelcomeImageLayout = styled(ImageLayout)`
  width : 450px;
  height : 450px;
`
const SpaceBetweenImageAndForm = styled.div`
  height: 10px;
`

const InputFieldWrapper = styled.div<{ haserror?: boolean }>`
  display: flex;
  width: 350px;
  gap: 10px;

  ${(props) => (props.haserror ? "border: 5px solid #ff0000" : "")};
  ${(props) => (props.haserror ? "margin: 0px 0px" : "margin: 5px 0px")};
  border-radius: 10px;
`;

const PasswordCheckBoxlayout = styled.div<{ isOpen: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  gap: 10px;
  width: 350px;
  justify-content: center;
  align-items: center;
  max-height: ${(props) => (props.isOpen ? "300px" : "0")};
  overflow: hidden;
  transition: max-height 0.5s ease;

  font: 700 12px 'Courier New', cursive;
`

const PasswordCheckBox = styled.div<{ isready: boolean }>`
  display: flex;
  align-items: center;
  width: 110px;
  color: ${(props) => (props.isready ? '#777' : '#ccc')};
`;

const EmptyIcon = styled.div`
  background-color: transparent;
  margin-right: 5px;
  border: none;
  width: 10px;
  height: 10px;
`;

const CheckIcon = styled.div`
  background-image: url('imgs/check-icon.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;

  margin-right: 5px;
  border: none;
  width: 10px;
  height: 10px;
`;

const ErrorMessageLayout = styled.div<{ isopen: boolean }>`
  width: 300px;
  max-height: ${(props) => (props.isopen ? "40px" : "0")};
  overflow: hidden;
  transition: max-height 0.5s ease;
`;

const InfoMessage = styled.div`
  width: 100%;
  color: #ccc;
  font-size: 14px;
  font: 700 12px 'Courier New', cursive;
`

const ErrorMessage = styled.div`
  width: 100%;
  color: red;
  font-size: 14px;
  font: 700 12px 'Courier New', cursive;
`

import React from "react";
import styled from "styled-components";

export const InputField = styled.input`
  text-align: left;
  width: 100%;
  height: 40px;
  
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  align-self: stretch;
  border: 1px solid #ffaa71;
  padding: 0 10px;

  &:focus {
    outline: 2px solid #ffaa71;
  }
`;

export const Dropdown = styled.select`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  align-self: stretch;
  border: 1px solid #ffaa71;
  padding: 0 10px;
`;

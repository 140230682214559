// NotFound.js
import React from 'react';
import { styled } from 'styled-components';

import { Link } from 'react-router-dom';

export const NotFoundPage = () => {
  return (
    <BodyLayout>
      <h1>404 - Page Not Found</h1>
      <Image404 src="imgs/404.png" alt="404" />
      <p>죄송합니다, 요청하신 페이지를 찾을 수 없습니다.</p>
      <Link to="/">홈으로 돌아가기</Link>
    </BodyLayout>
  );
};

const Image404 = styled.img`
  width: 512px;
  height: 512px;
  object-fit: cover;
`;

const BodyLayout = styled.main`
  color: #fff;
  background-color: #FFAA71;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  width: 100vw;
  height: 100vh;
`;
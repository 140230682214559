import * as React from "react";
import styled from 'styled-components';

// sdk
import { loginWithEmailAndPassword, loginWithGoogle } from '../sdks/firebase';

// design
import { 
  DefaultLayout, 
  ImageLayout, 
  Subtitle, 
  Text, 
  Link } from '../components/DefaultLayout';
import { Button } from '../components/Button';
import { InputField } from '../components/InputLayout';
import { useNavigate } from 'react-router-dom';


// Dummy Data
const infoCards = [
  {
    title: '수수료 0%',
    description: '숨겨진 수수료나 추후 청구되는 일이 전혀 없습니다! 걱정하지 말고 편하게 거래를 진행하세요!'
  },
  {
    title: '쉽고 간단한 거래 방식',
    description: '매번 원하는 품목이 있는지, 사고 싶어하는 사람이 있는지 스크롤을 올려보고 검색해보고... 이제 훨씬 간단하게 구매자 혹은 판매자와 연락하시고 간단하게 거래하세요 : )'
  },
  {
    title: '정보 유출 걱정 NO!',
    description: '저희는 어떠한 개인 정보도 요구하지 않습니다. 심지어 거래에 필요한 정보도 거래하시는 분들끼리만 공유하시면 되죠!'
  }
];


interface FormEvent extends React.FormEvent<HTMLFormElement> { }
interface ChangeEvent extends React.ChangeEvent<HTMLInputElement> { }

export const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [resultMessage, setResultMessage] = React.useState("");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const message = await loginWithEmailAndPassword(email, password);
    setResultMessage(message);
  };

  const handleSignup = async () => {
    navigate("/signup");
  };

  return (
    <DefaultLayout>
      <LoginImageLayout url="imgs/calimaru-title.png"/>
      <Subtitle>Buy, Sell, and Trade Without Extra Costs</Subtitle>
      <SpaceBetweenImageAndForm />
      <LoginFormLayout onSubmit={handleSubmit}>
        <InputField
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e: ChangeEvent) => setEmail(e.target.value)} />

        <InputField
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={(e: ChangeEvent) => setPassword(e.target.value)} />

        <Text>
          아직 계정이 없으신가요? <Link onClick={handleSignup}>회원가입</Link> 을 통해 더 많은 혜택을 누려보세요!
        </Text>

        <ButtonContainer>
          <Button enable={email !== "" && password !== ""} type="submit">Log In</Button>
        </ButtonContainer>
      </LoginFormLayout>

      <ErrorMessageLayout isopen={resultMessage !== ""}>
        <ErrorMessage> {resultMessage} </ErrorMessage>
      </ErrorMessageLayout>

      {/* <LoginButton url='google/light/web_light_sq_SI.svg' onClick={loginWithGoogle}></LoginButton> */}
    </DefaultLayout>
  );
};

const LoginImageLayout = styled(ImageLayout)`
  width: 350px;
  height: 100px;
`

const SpaceBetweenImageAndForm = styled.div`
  height: 40px;
`

const LoginFormLayout = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const ErrorMessageLayout = styled.div<{ isopen: boolean }>`
  width: auto;
  max-height: ${(props) => (props.isopen ? "40px" : "0")};
  overflow: hidden;
  transition: max-height 0.5s ease;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  font: 700 12px 'Courier New', cursive;
`
import React from 'react';
import styled from 'styled-components';
import { SearchBarProps } from '../types/types';

export const SearchBar: React.FC<SearchBarProps> = ({ placeholder, onSearch }) => {
    return (
        <SearchContainer>
            <SearchInput
                type="search"
                placeholder={placeholder}
                onChange={(e) => onSearch(e.target.value)}
                aria-label="Search marketplace items"
            />
        </SearchContainer>
    );
};

const SearchContainer = styled.div`
  border-radius: 20px;
  background-color: #FFAA71;
  position: relative;
  display: flex;
  height: 40px;
  width: 40vw;
  min-width: 300px;
  max-width: 100%;
  align-items: center;
  padding: 0px 10px;
  margin: 10px;
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  background: none;
  font-family: 'Sunflower', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  text-align: center;

  &::placeholder {
    color: #FFFFFF88;
  }
    
  &:focus {
    outline: none;
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 16px;
  width: 24px;
  display: flex;
  align-items: center;
`;

const IconCircle = styled.div`
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 4px solid rgba(170, 170, 170, 0.5);
`;
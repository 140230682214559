import {ReactNode, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../sdks/firebase';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children, redirectUrl = "/" }: { children: ReactNode, redirectUrl?: string;}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // 사용자 상태 업데이트
    });

    return () => unsubscribe(); // 컴포넌트 언마운트 시 리스너 해제
  }, []);

  if (isAuthenticated === null) {
    // 로딩 중 표시
    return <p>로딩 중...</p>;
  }

  if (isAuthenticated) {
    // 로그인된 사용자는 다른 페이지로 리다이렉트
    return <Navigate to={redirectUrl} replace />;
  }

  // 로그인되지 않은 사용자는 자식 컴포넌트를 렌더링
  return <>{children}</>;
};

export const AuthProtectedRoute = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // 사용자 상태 업데이트
    });

    return () => unsubscribe(); // 컴포넌트 언마운트 시 리스너 해제
  }, []);

  if (isAuthenticated === null) {
    // 로딩 중 표시
    return <p>로딩 중...</p>;
  }

  if (!isAuthenticated) {
    // 로그인되지 않은 사용자는 다른 페이지로 리다이렉트
    return <Navigate to="/" replace />;
  }

  // 로그인된 사용자는 자식 컴포넌트를 렌더링
  return <>{children}</>;
};

import React from 'react';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";

import { User } from "firebase/auth";
import { auth } from "../sdks/firebase";
import { UserProfile } from "./UserProfile";
import { UserMenuButton } from './UserMenuButton';

export const PrivateSection: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = React.useState<User | null>(null); // Firebase User 타입 사용

  const handleLoginButtonClick = async () => {
    navigate("/login");
  };
  const handleSignupButtonClick = async () => {
    navigate("/signup");
  };

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  return (
    <PrivateSectionLayout>
      {user ? (
        <UserInfoLayout>
          환영합니다, {user.displayName} 님! 
          <UserProfile photoURL={user.photoURL} userInitial={user.displayName?.at(0)} /> 
          <UserMenuButton />
        </UserInfoLayout>
      ) : (
        <UserInfoLayout>
          더 많은 혜택을 누리시려면&nbsp;<Link onClick={handleLoginButtonClick}>로그인</Link>&nbsp;혹은&nbsp;<Link onClick={handleSignupButtonClick}>회원가입</Link>을 해주세요
        </UserInfoLayout>
      )}
    </PrivateSectionLayout>
  );
};

const UserInfoLayout = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PrivateSectionLayout = styled.div`
  flex-shrink: 1; 
  flex-grow: 0;
  display: inline-block;
  font-family: 'Sunflower', sans-serif;
  text-align: right;
  font-size: 16px;
  font-weight: 700;
  white-space: normal;

  @media (max-width: 991px) {
    display: inline;
    font-size: 12px;
    text-align: center;
    overflow-wrap: break-word;
    font-size: 0px;
    height: 0px;
    visibility: hidden;
  }
`;

const Link = styled.span`
  font-weight: 700;
  text-decoration: underline;
  color: rgba(80, 124, 255, 1);
  cursor: pointer;
`;